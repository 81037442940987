import { createContext, useContext, useState, useEffect } from 'react';
import jsforce from 'jsforce';
import { client } from '../utils/client';
import * as actions from '../utils/persist';
import { getInstanceConfiguration } from '../utils/domain-mapping';

const AuthContext = createContext(undefined as any);

export const useToken = () => useContext(AuthContext);

const AuthProvider = (props: any) => {
  const getToken = (): jsforce.ConnectionOptions | null => {
    // First, try to get the token from persistent storage
    let userToken = actions.getToken();

    if (userToken) {
      client.setToken({ ...getInstanceConfiguration(), ...userToken });
    } else {
      // If no token in storage, check URL for `authToken` parameter
      const params = new URLSearchParams(window.location.search);
      const authToken = params.get('authToken');
      const instanceUrl = params.get('instanceUrl') || null;
      const localInstanceUrl = localStorage.getItem('instanceUrl');

      if (!localInstanceUrl && instanceUrl) {
        localStorage.setItem('instanceUrl', instanceUrl);
      }

      if (authToken && instanceUrl) {
        // Create a userToken object with the `authToken`
        userToken = { accessToken: authToken } as jsforce.ConnectionOptions;

        // Persist the token and set it in the client
        actions.setToken(userToken);
        client.setToken({ ...getInstanceConfiguration(), ...userToken });

        // Clean up the URL by removing the `authToken` parameter
        window.history.replaceState({}, document.title, window.location.pathname);
      }
    }

    return userToken;
  };

  const [token, setToken] = useState<jsforce.ConnectionOptions | null>(getToken());

  const saveToken = (userToken: jsforce.ConnectionOptions) => {
    actions.setToken(userToken); // Persist token to storage
    setToken(userToken); // Update the token in context
  };

  useEffect(() => {
    // Ensure client always has the latest token from context
    if (token) {
      client.setToken({ ...getInstanceConfiguration(), ...token });
    }
  }, [token]);

  return <AuthContext.Provider value={[token, saveToken]} {...props}></AuthContext.Provider>;
};

export default AuthProvider;
