import { Grid } from '@material-ui/core';
import { ConnectionOptions } from 'jsforce';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { BackdroppedCircularProgress, EmptyContainer } from '../components';
import { useToken } from '../hooks/useToken';
import { client } from '../utils/client';
import { routes } from '../utils/constants';

export const Callback = () => {
  const params = new URLSearchParams(window.location.search);
  const code = params.get('code') as string;
  const sfdxCommunityUrl = params.get('sfdc_community_url') as string;

  const history = useHistory();
  const [, setToken] = useToken();
  const [authError, setAuthError] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (code) {
      client.updateLoginUrl(sfdxCommunityUrl);

      client
        .authorize(code)
        .then(async (newToken: ConnectionOptions) => {
          // "instanceUrl" returned by "authorize" doesn't work for uploading docs, so relying on "sfdxCommunityUrl"
          const token = { ...newToken, instanceUrl: sfdxCommunityUrl, loginUrl: sfdxCommunityUrl };
          setToken(token);
          localStorage.setItem('instanceUrl', sfdxCommunityUrl);

          // Only proceed if accessToken is defined
          if (token.accessToken) {
            try {
              // Retrieve API version
              const apiVersionResponse = await client.getApiVersion();
              let redirectUrl;

              const instanceUrl = encodeURIComponent(sfdxCommunityUrl);

              // Check the API version and redirect accordingly
              if (apiVersionResponse.mvn__PP_Portal_API_Version__c === 'v3') {
                redirectUrl = `https://current.test.pubs.komodohealth.com?authToken=${encodeURIComponent(
                  token.accessToken
                )}&instanceUrl=${instanceUrl}`;
              } else if (apiVersionResponse.mvn__PP_Portal_API_Version__c === 'v2') {
                redirectUrl = `https://previous.test.pubs.komodohealth.com?authToken=${encodeURIComponent(
                  token.accessToken
                )}&instanceUrl=${instanceUrl}`;
              } else {
                redirectUrl = `https://deprecated.test.pubs.komodohealth.com?authToken=${encodeURIComponent(
                  token.accessToken
                )}&instanceUrl=${instanceUrl}`;
              }

              window.location.href = redirectUrl;
            } catch (versionError) {
              console.error('Error retrieving API version:', versionError);
              setAuthError('Failed to retrieve API version.');
            }
          } else {
            // Handle the case where accessToken is undefined (optional)
            console.error('Authorization token is undefined');
            setAuthError('Authorization token is undefined');
          }
        })
        .catch((error) => {
          console.log('An error occured when logging to the Salesforce organization:', error);
          setAuthError(error);
          setToken(undefined);
        });
    }
  }, [code, history]);

  if (authError) {
    return (
      <EmptyContainer
        icon={'error'}
        title={'Login Failed'}
        message={
          'An error occured when logging into the Salesforce organization. Please, reach out to an administrator.'
        }
        actions={[{ label: 'Return to Login', url: routes.login.routeProps.path[0] }]}
      />
    );
  }

  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <BackdroppedCircularProgress open={true} />
      </Grid>
    </Grid>
  );
};
