import jsforce from 'jsforce';
import { sampleUserPicklistInfos } from '../data/profile';
import {
  ApiConstructorOptions,
  ApiVersionResponse,
  CursorResponse,
  GetProfilePicklistOptions,
  GetProfileResponse,
  PublicationDetailOptions,
  PublicationDetailResponse,
  PublicationTaskCloseOptions,
  SupportingDocumentDownloadLinkOptions,
  SupportingDocumentDownloadLinkResponse,
  UpdateProfileRequest,
  UpdateProfileResponse,
  UploadContentVersionOptions,
  UserPicklistOptions,
} from '../typings';
import { CLIENT_ID, REDIRECT_URI } from './constants';
import { convertFileToBase64 } from './format';

/**
 * Api class for sending requests
 */
export class Api {
  private conn: jsforce.Connection;
  private resetPasswordPage: string;

  constructor(opts: ApiConstructorOptions) {
    const {
      clientId,
      salesforceApiVersion = '53.0',
      loginUrl,
      redirectUri,
      instanceUrl,
      resetPasswordPage = 'mvn__SetPassword',
    } = opts;

    this.conn = new jsforce.Connection({ loginUrl, version: salesforceApiVersion, clientId, redirectUri, instanceUrl });
    this.resetPasswordPage = resetPasswordPage;
  }

  /**
   * Replaces the Login URL in the OAuth2 object
   * @param loginUrl the new login URL
   */
  updateLoginUrl = (loginUrl: string) => {
    this.conn.oauth2 = new jsforce.OAuth2({
      loginUrl,
      clientId: CLIENT_ID,
      redirectUri: REDIRECT_URI,
    });
  };

  /**
   * Returns the Document Download URL
   * @param idOrPath the path to the file or id
   * @returns the full URL to download the file
   */
  getDocumentDownloadUrl = (idOrPath: string) => {
    if (idOrPath.startsWith('http') && idOrPath.includes('/sfc/servlet')) {
      const index = idOrPath.indexOf('/sfc/servlet');
      return `${this.conn.instanceUrl}${idOrPath.slice(index)}`;
    } else if (idOrPath.includes('/')) {
      return `${this.conn.instanceUrl}${idOrPath}`;
    } else {
      return `${this.conn.instanceUrl}/sfc/servlet.shepherd/document/download/${idOrPath}`;
    }
  };

  /**
   * Returns the Zip File Document Download Url
   * @param listOfIds list of ids seperated by "/"
   * @returns the full URL to download the zip file
   */
  getDocumentAllDownloadUrl = (idOrPath: string) => {
    return `${this.conn.instanceUrl}/sfc/servlet.shepherd/document/download/${idOrPath}`;
  };

  /**
   * Get & return the token from Salesforce
   * @param code the authorization code
   * @returns the token to persist
   */
  authorize = async (code: string): Promise<Record<string, string | undefined>> => {
    await this.conn.authorize(code);

    return {
      instanceUrl: this.conn.instanceUrl,
      accessToken: this.conn.accessToken,
      refreshToken: this.conn.refreshToken,
    };
  };

  /**
   * Update the jsforce token
   * @param props the jsforce token
   */
  setToken = (props: jsforce.ConnectionOptions) => {
    this.conn = new jsforce.Connection({ ...this.conn.oauth2, ...props });
  };

  /**
   * Revoke the token
   */
  logout = async (): Promise<string> => {
    try {
      await this.conn.logout();
    } catch (e) {
      console.log('Error while revoking token:', e);
    }

    const retUrl = encodeURIComponent(this.conn.oauth2.loginUrl);
    return `${this.conn.oauth2.loginUrl}/secur/logout.jsp?retUrl=${retUrl}`;
  };

  /**
   * Returns the Change Password URL
   * @returns reset password url
   */
  getChangePassword = (): string => {
    return `${this.conn.oauth2.loginUrl}/${this.resetPasswordPage}`;
  };

  /**
   * Returns the Authorization URL
   * @returns the url
   */
  getAuthorizationUrl = () => {
    return this.conn.oauth2.getAuthorizationUrl({ scope: 'api id web' });
  };

  /**
   * Executes a request to Salesforce
   * @param info
   * @returns the response
   */
  do = async <T>(info: string | jsforce.RequestInfo) => {
    let request: string | jsforce.RequestInfo;
    const headers = { 'accept-version': localStorage.getItem('APIVersion') };

    if (typeof info === 'string') {
      request = { headers, method: 'GET', url: this.conn.instanceUrl + info };
    } else {
      request = { ...info, headers, url: this.conn.instanceUrl + info.url };
    }

    try {
      const response = await this.conn.request<T>(request);
      return response;
    } catch (error) {
      if (error instanceof Error && error.message === 'Access Declined') {
        // window.location.href = this.getAuthorizationUrl();
      } else if (error instanceof Error && error.message === 'invalid client credentials') {
        console.log('logging the user out due to invalid client credentials');
        request = { headers, method: 'GET', url: this.conn.instanceUrl + info };
        const logoutUrl = `${this.conn.oauth2.loginUrl}/secur/logout.jsp?retUrl=${encodeURIComponent(
          this.conn.oauth2.loginUrl
        )}`;
        request.url = logoutUrl;
        return await this.conn.request<T>(request);
      }

      throw error;
    }
  };

  /**
   * Returns the running user profile
   * @returns the profile
   */
  getProfile = async (): Promise<GetProfileResponse> => {
    const url = `/services/apexrest/mvn/pubs/profile`;

    return this.do<GetProfileResponse>(url);
  };

  getProfilePicklistOptions = async (): Promise<GetProfilePicklistOptions> => {
    const masterRecordType: string = '012000000000000AAA';
    const url = `/services/data/v53.0/ui-api/object-info/User/picklist-values/` + masterRecordType;
    // For some strange reason, the handler mock for this function refuses to return the sample data.
    // Return the sample data manually for now.
    const isJestRunning = process.env.JEST_WORKER_ID !== undefined;
    const picklistInfos: UserPicklistOptions = isJestRunning
      ? (sampleUserPicklistInfos as UserPicklistOptions)
      : await this.do<UserPicklistOptions>(url);
    let profilePicklistOptions: GetProfilePicklistOptions = {
      timezonePicklistOptions: new Map<string, string>(),
      pronounPicklistOptions: new Map<string, string>(),
      genderPicklistOptions: new Map<string, string>(),
      countryPicklistOptions: new Map<string, string>(),
      statePicklistOptions: new Map<string, string>(),
    };
    for (var timezoneOption of picklistInfos.picklistFieldValues.TimeZoneSidKey.values) {
      profilePicklistOptions.timezonePicklistOptions.set(timezoneOption.value, timezoneOption.label);
    }
    for (var pronounOption of picklistInfos.picklistFieldValues.mvn__PP_Pronoun__c.values) {
      profilePicklistOptions.pronounPicklistOptions.set(pronounOption.value, pronounOption.label);
    }
    for (var genderOption of picklistInfos.picklistFieldValues.mvn__PP_Gender__c.values) {
      profilePicklistOptions.genderPicklistOptions.set(genderOption.value, genderOption.label);
    }
    for (var countryOption of picklistInfos.picklistFieldValues.CountryCode.values) {
      profilePicklistOptions.countryPicklistOptions.set(countryOption.value, countryOption.label);
    }
    for (var stateOption of picklistInfos.picklistFieldValues.StateCode.values) {
      profilePicklistOptions.statePicklistOptions.set(stateOption.value, stateOption.label);
    }
    return profilePicklistOptions;
  };

  /**
   * Updates the User Profile
   * @param request the request
   * @returns the outcome
   */
  updateProfile = async (request: UpdateProfileRequest) => {
    const url = `/services/apexrest/mvn/pubs/profile`;
    const body = JSON.stringify(request);

    return this.do<UpdateProfileResponse>({ method: 'POST', url, body });
  };

  /**
   * Returns a single publication
   * @param opts the options
   * @returns the publication
   */
  getPublication = async (opts: PublicationDetailOptions): Promise<PublicationDetailResponse> => {
    const url = `/services/apexrest/mvn/pubs/publications/${encodeURIComponent(opts.id)}`;
    return this.do<PublicationDetailResponse>(url);
  };

  /**
   * Returns the supporting document download link
   * @param opts the options
   * @returns the download link
   */
  getSupportingDocumentDownloadLink = async (opts: SupportingDocumentDownloadLinkOptions) => {
    const publicationId = encodeURIComponent(opts.publicationId);
    const documentId = encodeURIComponent(opts.documentId);
    const url = `/services/apexrest/mvn/pubs/publications/${publicationId}/supportingDocuments/${documentId}/requestDownload`;

    return this.do<SupportingDocumentDownloadLinkResponse>({ method: 'POST', url, body: '' });
  };

  /**
   * Returns the current api version of the org and version of the portal
   * @returns the api version and version of the portal in JSON format
   */
  getApiVersion = async (): Promise<ApiVersionResponse> => {
    if (!localStorage.getItem('APIVersion')) {
      const url = `/services/apexrest/mvn/pubs/apiVersion/`;
      const apiVersionResponse = this.do<ApiVersionResponse>(url) as Promise<ApiVersionResponse>;
      localStorage.setItem('APIVersion', (await apiVersionResponse).mvn__PP_Portal_API_Version__c);
      return apiVersionResponse;
    }

    const apiVersion = localStorage.getItem('APIVersion');
    const temp = <ApiVersionResponse>{
      mvn__PP_isActive__c: true,
      mvn__PP_Portal_API_Version__c: apiVersion,
      mvn__PP_Pubs_API_Version__c: apiVersion,
    };
    return temp;
  };

  /**
   * Returns a cursor response
   * @param path the url path
   * @param params the options
   * @returns the cursor
   */
  list = async <T>(path: string, params?: any): Promise<CursorResponse<T>> => {
    let url = `/services/apexrest/mvn/pubs/${path}${this.appendParams(params)}`;

    return this.do(url);
  };

  /**
   * Uploads a Content Version Document
   * @param opts the options
   * @returns the upload confirmation
   */
  uploadContentVersion = async (opts: UploadContentVersionOptions): Promise<jsforce.RecordResult> => {
    const params = {
      PathOnClient: opts.pathOnClient,
      ReasonForChange: opts.reasonForChange ?? '',
      VersionData: await convertFileToBase64(opts.file),
      mvn__PP_Source__c: 'Collaborator Portal',
      mvn__PP_Pending_Parenting__c: true,
    };

    return this.conn.sobject('ContentVersion').create(params);
  };

  /**
   * Closes a Task record
   * @param opts the options
   * @returns the operation outcome
   */
  closeTask = async (opts: PublicationTaskCloseOptions) => {
    const { id, verdict, comments, uploadedFiles } = opts;
    const taskId = encodeURIComponent(id);
    const url = `/services/apexrest/mvn/pubs/tasks/${taskId}/closeTask`;
    // @ts-ignore
    const revisionIds = uploadedFiles && uploadedFiles.map((file) => file.value?.id).filter((id): id is string => !!id);

    const body = JSON.stringify({ verdict, comments, revisionIds });

    return this.do<PublicationTaskCloseOptions>({ method: 'POST', url, body });
  };

  /**
   * Combines URL params & returns a query string
   * @param params the query string params
   * @returns the query string
   */
  private appendParams = (params?: Record<string, any>) => {
    if (!params) {
      return '';
    }

    const searchParams = new URLSearchParams();

    for (const [key, value] of Object.entries(params)) {
      searchParams.set(key, value);
    }

    return '?' + searchParams.toString();
  };
}
