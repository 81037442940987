/**
 * Represents the React Router routes
 */
export const routes = {
  callback: {
    routeProps: { path: ['/services/oauth2/callback/'] },
  },
  login: {
    routeProps: { path: ['/login'] },
  },
  logout: {
    routeProps: { path: ['/logout'] },
  },
  publications: {
    routeProps: { path: ['/publications', '/publications/active', '/publications/archived'], exact: true },
    active: {
      routeProps: { path: ['/publications/active', '/publications'], exact: true },
    },
    archived: {
      routeProps: { path: ['/publications/archived'], exact: true },
    },
  },
  tasks: {
    routeProps: { path: ['/tasks'] },
    open: {
      routeProps: { path: ['/tasks/open', '/tasks'], exact: true },
    },
    closed: { routeProps: { path: ['/tasks/closed'], exact: true } },
  },
  publicationDetails: {
    routeProps: { path: ['/publications/:id([a-zA-Z0-9]{15}|[a-zA-Z0-9]{18})'] },
  },
  profile: {
    routeProps: { path: ['/profile'] },
    edit: {
      routeProps: { path: ['/profile/edit', '/profile'], exact: true },
    },
  },
};

const instanceUrlOverride = new URLSearchParams(window.location.search).get('salesforceInstanceUrl');

/**
 * Represents the Redirect URI used by the jsforce client
 */
export const REDIRECT_URI = window.location.origin + routes.callback.routeProps.path;

/**
 * Represents the Reset Password Page API Name
 */
export const RESET_PASSWORD_PAGE = process.env.REACT_APP_RESET_PASSWORD_PAGE;

/**
 * Represents the Salesforce Client ID
 */
export const CLIENT_ID = process.env.REACT_APP_PORTAL_AUTH_CLIENT_ID as string;

/**
 * Represents if Multi Org is enabled
 */
export const ENABLE_MULTIORG = process.env.REACT_APP_ENABLE_MULTIORG === 'true';

/**
 * Represents the Salesforce Instance URL
 */
export const INSTANCE_URL = instanceUrlOverride as string;
